<!--批发退货申请-->
<template>
    <div class="Refund">
        <el-card shadow="never" style="margin-top: 8px">
            <el-form @submit.native.prevent :inline="true" v-model="form" size="small" label-width="80px">
                <el-row>
                    <el-col :span="8">
                        <el-form-item label="单据号">
                            <el-input placeholder="" v-model="form.code" style="width: 215px" />
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="类型">
                            <el-select placeholder="请选择" v-model="form.type" @change="form.deptCode = ''">
                                <el-option label="请选择" value="" />
                                <el-option label="采购退货" :value="0" />
                                <el-option label="自建退货" :value="1" />
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="销售机构">
                            <el-select placeholder="请选择" v-model="form.repoCode" filterable>
                                <el-option label="请选择" value="" />
                                <el-option
                                    v-for="dept in meta.repoDepts"
                                    :label="dept.name"
                                    :value="dept.code"
                                    :key="dept.code"
                                />
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row style="margin-top: 16px">
                    <el-col :span="8">
                        <el-form-item label="进货机构">
                            <el-select v-if="form.type === 0" placeholder="请选择" v-model="form.deptCode" filterable>
                                <el-option label="请选择" value="" />
                                <el-option
                                    v-for="dept in meta.storeDepts"
                                    :label="dept.name"
                                    :value="dept.code"
                                    :key="dept.code"
                                />
                            </el-select>
                            <el-input v-else v-model.trim="form.deptCode" />
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <ef-review-status v-model="form.reviewStatus" />
                    </el-col>
                    <el-col :span="8">
                        <ef-start-date v-model="form.startTime" />
                    </el-col>
                </el-row>
                <el-row style="margin-top: 16px">
                    <el-col :span="8">
                        <ef-end-date v-model="form.endTime" />
                    </el-col>
                </el-row>
            </el-form>
        </el-card>
        <el-card shadow="never" style="margin-top: 8px; text-align: left">
            <el-button type="primary" @click="handleQuery" size="small" v-if="hasPrivilege('menu.sale.refund.open')">
                查询
            </el-button>
            <el-button type="primary" @click="handleCreate" size="small" v-if="hasPrivilege('menu.sale.refund.create')">
                新建
            </el-button>
            <el-button type="primary" @click="handleAudit" size="small" v-if="hasPrivilege('menu.sale.refund.review')">
                审核
            </el-button>
            <el-button type="primary" @click="handleExport" size="small" v-if="hasPrivilege('menu.sale.refund.export')">
                导出
            </el-button>
            <span style="float: right">
                <el-button size="mini" @click="showCheckTableShowColumnDialog">表头</el-button>
                <el-button size="mini" @click="handlePrint">打印</el-button>
            </span>
        </el-card>
        <check-table-show-column-dialog ref="checkTableShowColumnDialog" @checkTableShowColumn="setShowTableColumns" />
        <ef-review-biz ref="review" baseUrl="/trade/wholesale/refund" @completed="handleQuery" />
        <el-card shadow="never" style="margin-top: 8px">
            <el-table
                id="printMe"
                border
                stripe
                ref="table"
                style="width: 100%"
                :data="tableData"
                size="mini"
                :highlight-current-row="true"
                max-height="450"
            >
                <el-table-column label="全选" type="selection" width="60" align="center" fixed="left" />
                <el-table-column label="序号" width="60" type="index" fixed="left" />
                <el-table-column label="编码" width="170" prop="code" v-if="showColumn('code')" />
                <el-table-column label="申请单号" width="170" prop="bizCode" v-if="showColumn('bizCode')" />
                <el-table-column label="销售机构" min-width="120" prop="repoName" v-if="showColumn('repoName')" />
                <el-table-column label="进货机构" min-width="120" prop="deptName" v-if="showColumn('deptName')" />
                <el-table-column label="类型" width="100" prop="type" v-if="showColumn('type')" key="type">
                    <template slot-scope="scope">
                        <span>{{ scope.row.type | type }}</span>
                    </template>
                </el-table-column>
                <el-table-column
                    label="单据金额"
                    width="100"
                    prop="bizMoney"
                    v-if="showColumn('bizMoney')"
                    key="bizMoney"
                >
                    <template slot-scope="scope">
                        <span>{{ scope.row.bizMoney | money }}</span>
                    </template>
                </el-table-column>
                <el-table-column label="数量" width="80" prop="count" v-if="showColumn('count')" key="count" />
                <el-table-column
                    label="审核状态"
                    width="80"
                    prop="reviewStatus"
                    v-if="showColumn('reviewStatus')"
                    key="reviewStatus"
                >
                    <template slot-scope="scope">
                        <span>{{ scope.row.reviewStatus | reviewStatus }}</span>
                    </template>
                </el-table-column>
                <el-table-column label="创建人" width="100" prop="creator" v-if="showColumn('creator')" />
                <el-table-column label="创建时间" width="140" prop="createTime" v-if="showColumn('createTime')" />
                <el-table-column
                    label="操作"
                    min-width="222"
                    header-align="center"
                    v-if="showColumn('operate')"
                    prop="operate"
                    key="operate"
                >
                    <template slot-scope="scope">
                        <el-button
                            size="mini"
                            @click="rowDetail(scope.row)"
                            v-if="hasPrivilege('menu.sale.refund.open')"
                            >查看
                        </el-button>
                        <!--采购退货类型的批发退货单据无法修改和删除-->
                        <el-button
                            size="mini"
                            type="warning"
                            @click="rowEdit(scope.row)"
                            v-if="hasPrivilege('menu.sale.refund.edit')"
                        >
                            编辑
                        </el-button>
                        <el-button
                            size="mini"
                            type="danger"
                            @click="handleDelete(scope.row)"
                            v-if="hasPrivilege('menu.sale.refund.delete')"
                        >
                            删除
                        </el-button>
                    </template>
                </el-table-column>
            </el-table>
            <el-pagination
                :page-sizes="page.pageSizes"
                :page-size="form.limit"
                :layout="page.PageStyle"
                :total="page.total"
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                @prev-click="handlePrevClick"
                @next-click="handleNextClick"
            />
        </el-card>
    </div>
</template>

<script>
import Util from 'js/Util';
import MoneyUtils from 'js/MoneyUtils';
import UrlUtils from 'js/UrlUtils';
import EfStartDate from 'components/EfStartDate';
import EfEndDate from 'components/EfEndDate';
import EfReviewBiz from 'components/EfReviewBiz';
import { DeptTypeEnum } from 'js/DeptCommon';
import EfReviewStatus from 'components/EfReviewStatus';
import CheckTableShowColumn from 'components/mixins/CheckTableShowColumn';
import CheckTableShowColumnDialog from 'components/CheckTableShowColumnDialog';

export default {
    name: 'Refund',
    mixins: [CheckTableShowColumn],
    components: { EfEndDate, EfStartDate, EfReviewBiz, EfReviewStatus, CheckTableShowColumnDialog },
    data() {
        return {
            form: {
                code: '',
                type: '',
                reviewStatus: '',
                search: '',
                deptCode: '',
                repoCode: '',
                startTime: Util.formatDateOfLastMonth(),
                endTime: Util.nowFormatDate(),
                page: 1,
                limit: Util.Limit,
            },
            auditCodes: [],
            audit: {
                auditDate: '',
                advice: '1',
                remark: '',
            },

            page: {
                total: 0,
                pageSizes: Util.PageSizes,
                PageStyle: Util.PageStyle,
            },
            meta: {
                repoDepts: [],
                storeDepts: [],
            },
            current: {
                typeIdx: 3,
                deptCode: '',
            },
            loading: false,
            tableData: [],
            url: {
                page: '/trade/wholesale/refund/page',
                review: '/trade/wholesale/refund/review/',
                delete: '/trade/wholesale/refund/delete',
            },
        };
    },
    mounted() {
        this.$efApi.deptApi.managedDeptsByDeptType(DeptTypeEnum.REPOSITORY).then((rst) => {
            this.meta.repoDepts = rst;
            this.form.repoCode = this.meta.repoDepts[0].code;
            this.handleQuery();
        });
        this.$efApi.deptApi.companyAllStores().then((rst) => {
            this.meta.storeDepts = rst;
        });
    },
    activated() {
        this.executeQueryIfNeedReload(this.handleQuery);
    },
    computed: {},
    filters: {
        reviewStatus(reviewStatus) {
            if (typeof reviewStatus == 'undefined') {
                return '';
            }
            if (reviewStatus == '0') {
                return '待审核';
            }
            if (reviewStatus == '2') {
                return '已审核';
            }
            if (reviewStatus == '-1') {
                return '已拒绝';
            }
        },
        type(type) {
            if (typeof type == 'undefined') {
                return '';
            }
            if (type == '0') {
                return '采购退货';
            }
            if (type == '1') {
                return '自建退货';
            }
        },
        money(money) {
            if (typeof money != 'undefined') {
                return MoneyUtils.formatMoney(MoneyUtils.moneyToYuan(money));
            }
            return '';
        },
    },
    methods: {
        handlePrint() {
            this.printHTML('printMe', this.$route.meta.title);
        },
        rowDetail(row) {
            Util.nameJump(this, 'menu.sale.refund.detail', ['销售管理', '批发退货申请', '退货申请详情'], {
                form: row,
                code: row.code,
            });
        },
        rowEdit(row) {
            if (row.reviewStatus !== 0) {
                this.$message.error('非待审核单据不能编辑');
            } else {
                Util.nameJump(this, 'menu.sale.refund.edit', ['销售管理', '批发退货申请', '编辑退货申请'], {
                    form: row,
                    code: row.code,
                });
            }
        },
        handleDelete(row) {
            if (row.reviewStatus !== 0) {
                this.$message.error('非待审核单据不能删除');
            } else {
                UrlUtils.DeleteRemote(this, this.url.delete, row.code);
            }
        },
        handleAudit() {
            this.$refs.review.open(this.$refs.table.selection);
        },
        handleQuery() {
            const _this = this;
            const _params = { params: _this.form };
            Util.queryTable(_this, _this.url.page, _params);
        },
        handleCreate() {
            Util.nameJump(this, 'menu.sale.refund.create', ['销售管理', '批发退货申请', '新建退货申请']);
        },
        handleExport() {
            const codes = (this.$refs.table.selection || []).map((e) => e.code);

            UrlUtils.Export(this, '批发退货申请', '/trade/wholesale/refund/export', this.form, codes);
        },
    },
};
</script>

<style scoped>
.Refund .el-form-item {
    margin-bottom: 0;
}
</style>
